<template>
	<v-dialog :width="mwstylesettings.SmallDialogWidth" v-model="dialog">
		<template v-slot:activator="{ on }">
			<v-btn color="primary" block v-on="on">{{mwtr.AddSectionPartButton}} </v-btn>
		</template>
		<v-card>
			<v-card-title></v-card-title>
			<v-card-text>
				<v-select v-model="goalGroup" :items="items"></v-select>						
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary"  @click="create">{{mwtr.AddSectionPartButton}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";

	export default {
		name: "NewSectionPart", 
		props: {
			section: {}, 
			index: {}
		},
		data: () => {
			return {
				goalGroup: "", 
				dialog: false
			}
		},
		computed: {
			...Vuex.mapState({
				goalGroups: state => state.goalGroups
			}),
			items(){
				return this.mwutilities.itemsArray(this.goalGroups)
			}
		},
		methods: {
			create(){
				var self = this;
				var data = {
					goalGroup: this.goalGroup,
					position: this.index
				}
				self.$store.dispatch("sections/addPart", {data: data, section: self.section }).then( () => {
					self.$emit("newPart" );
					self.goalGroup = "";
					self.dialog = false;
					self.saved = true;
				})
			}, 
		},
		watch: {
		
		}
		// created(){
			
		// }

	}
// </script>"