<template>
<v-list-item :class="{'active': active }" @click="$emit('click')"> 
	<v-list-item-content v-if="goalGroup">
		<v-list-item-title>{{goalGroup.name}}</v-list-item-title>
		<v-list-item-subtitle>{{mwtr.GoalsInGroup}}: {{part.goals ? part.goals.length : 0}}</v-list-item-subtitle>
	</v-list-item-content>
	<v-list-item-icon>
		<v-btn color="grey" small icon @click="$emit('delete')"><v-icon small>mdi-delete</v-icon></v-btn>
		<v-btn color="grey" small icon @click="$emit('up')"><v-icon small>mdi-arrow-up-circle</v-icon></v-btn>
		<v-btn color="grey" small icon @click="$emit('down')" class="ml-0"><v-icon small>mdi-arrow-down-circle</v-icon></v-btn>
	</v-list-item-icon>
</v-list-item>

</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "SectionPartListItem", 
		props: {
			id: {}, 
			active: {}, 
			part: {}
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },

		computed: {
			...Vuex.mapState( {
				goalGroups: state => state.goalGroups,
			}),
			goalGroup(){
				var id = this.part.goalGroup
				return this.goalGroups[id] || false
			}
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"					