<template>
	<v-sheet flat>	
		<v-row>		
			<v-col cols="12" sm="4" class="sidebar" height="100%">
				<v-list v-model="activePart" class="mb-2">
					<SectionPartListItem 
					v-for="partId in order" :key="partId"  
					:id="partId" :part="parts[partId]" :active="activePart == partId"
					@delete="deletePart(partId)"
					@click="activePart = partId" @up="move(partId, 'up')" @down="move(partId, 'down')"
					></SectionPartListItem>
				</v-list>
				<NewSectionPart :section="id" :index="order.length"></NewSectionPart>
				<v-btn block class="mt-2" v-if="orderChanged" color="igblack" dark @click="saveOrder">{{mwtr.SaveLayout}}</v-btn>
			</v-col>
			<v-col cols="12" sm="8">
				<EditSectionPart v-if="activePart" :id="activePart" :section="id">
				</EditSectionPart>
			</v-col>
		</v-row>
		
		<Saved :trigger="saved" @close="saved = false"></Saved>
	</v-sheet>
</template>

<style lang="less">
	@import "../../../assets/css/variables.less";

	.sidebar{
		border-right: 1px solid #e1e1e1;
	}
	.active {
		background-color: #eee;
		border-left: 4px solid @ig-accent; 
	}
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import Saved from "@/components/notices/Saved"
	import NewSectionPart from "@/components/admin/forms/NewSectionPart";
	import EditSectionPart from "@/components/admin/edits/EditSectionPart";
	import SectionPartListItem from "@/components/admin/panels/SectionPartListItem";

	export default {
		name: "EditSection", 
		props: {
			id: {}
		},
		data: () => {
			return {
				activePart: false,
				orderChanged: false, 
				saved: false
			}
		},
		computed: {
			...Vuex.mapState( {
				goals: state => state.goals,
				goalGroups: state => state.goalGroups,
				tags: state => state.tags,
				section(state){
					var id = this.id
					return state.sections[id] || {}
				}
			}),
			parts(){
				return this.section.parts || {}
			},
			order(){
				return this.mwutilities.getPositions(this.parts)
			}

		},
		components: {
			Saved,
			NewSectionPart, 
			EditSectionPart,
			SectionPartListItem
		},
		methods: {
			setActivePart(partId){
				this.activePart = false;
				this.activePart = partId;
			},		
			deletePart(id){
				// var self = this;
				// var i = self.order.indexOf( id )
				// // self.order.splice( i, 1);
				// // self.saveOrder();
				this.$store.dispatch("sections/deletePart", {
					part: id,
					section: this.id  
				})
			},
			move( id, direction ){
				this.mwutilities.moveItemInArray(id, this.order, direction);
				this.orderChanged = true;
			},
			saveOrder(){
				this.order.forEach( ( key, i) => {
					this.$store.dispatch("updateDoc", {
						doc: `sections/${this.id}/parts/${key}`,
						data: {position: i}
					}).then( ( ) => {
						this.saved = true;
						this.orderChanged = false;
					})
				} )
			}

		},
		created(){
			if( this.id ){			
				this.$store.dispatch("sections/fetchOne", this.id)
				this.$store.dispatch("sections/fetchParts", this.id)
			}
			this.$store.dispatch("tags/fetchAll");
			this.$store.dispatch("goals/fetchAll");
			this.$store.dispatch("goalGroups/fetchAll");
		},
		destroyed(){
			this.$store.dispatch("detachOne", this.id)
		},

	}
// </script>"